import styled from "@hellodarwin/core/lib/plugins/styled";
import theme from "../../utils/styles/theme";

export const HeaderStyled = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  padding: 16px 40px;
  background-color: ${theme.colors.white_1};
  border-bottom: 1px solid ${theme.colors.primary};
  z-index: 900;
  height: ${({ theme }) => theme.appearance.headerHeight};
  gap: 20px;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.l}px) {
    padding: 16px 24px;
  }
`;

